import { useState } from 'react'
import Sail from '../components/Sail'
import Pill from '../components/Pill'

const regate = [
    { size: 88, name: 'Grand voile lattée à corne', description: ' 3Di Endurance black 760 M, 22.400 Dpi + offshore ply.  Cunningham , retour du nerf de chute par le guindant. <br> Toutes les lattes en sandwich carbonne  CT33 S (Nouvelle Zélande), permettant  une stabilité de profil accrue et un poids réduit.'},
    { size: 60, name: 'Génois à recouvrement', description: "3Di Endurance 760 M, 22.400 Dpi + offshore ply.  Guindant zippé, patch d'écoute velcro pour voile enroulée sur stockeur structurel."},
    { size: 95, name: 'Code 0', description: "Capelage, hooké karver, amuré sur enmagasineur karver, 3Di Endurance 760 M 12.600 Dpi + offshore ply, cable  anti torsion Den Ran, SAC Spi North custom aux dimension d’un demi  trampoline."},
    { size: 23, name: 'Spi de tête runner /A2', description:"SuperKote SK 150 guindant/SK 130 chute, chaussette  ATN, SAC North."},
]

const techniqueVoile = [
    { size: 89, name: "Grand Voile à corne full-battened", description: 'Convoyage, croisière 2 ris, 5 lattes, 450 Gr PRESTIGE Contender Fibercon Pro Hybrid Dyneema tissé - coupe tri-radiale  (Hydranet).'},
    { size: 64, name: 'J1 / Génois zippé', description: "Convoyage, lattes de chute, 450 Gr PRESTIGE Contender Fibercon Pro Hybrid Dynema tissé - coupe triradiale – (Hydranet)."},
    { size: 42, name: 'J2/trinquette RÉGATE', description: "Hooké karver, amuré sur enmagasineur karver, Membrane taffeta/taffeta, Renforts rayonnants Lattes de chute, SAC North custom, 135cm, aux dimension d’un demi trampoline."},
    { size: 20, name: 'J3 / ORC', description: "Membrane taffeta/taffeta Renforts rayonnants Lattes de chute, drisses moufles, amuré sur emmagasineur facnor. SAC North."},
    { size: 10, name: 'Tourmentin', description: "380 Gr OCÉAN Près Dacron haute ténacité, orange, coupe  horizontale."},
    { size: 19, name: 'Gennaker type code D', description: "Sans cable anti-torsion, OCÉAN Portant Polyester  4,5oz, Stormlite 510 Coupe T-Cut, amuré sur enmagasineur karver."},
    { size: 15, name: 'A3, medium reacher', description: "RÉGATE Portant Nylon 1.5oz, Superkote 150, Coupe T-Cut, collé/cousu, chaussette ATN, SAC North."},
]

const sailsSets = [regate, techniqueVoile]

const Sails = () => {
    const [sailSetIndex, setSailSetIndex] = useState(0)
    const sailSet = sailsSets[sailSetIndex]
    return (
        <div id="Presentation" className='slide section'>
            <div className="slide-wrapper h-full">
                <div className="flex items-end justify-between pr-8">
                    <h1 className='text-6xl font-black text-primary boat-title pl-2 -mb-2'>Gréement</h1>
                </div>
                <hr className="boatline h-2"/>
                <p className='mt-4'>Zed 7 dispose de 2 jeux de voiles:</p>
                <div className="flex my-8 gap-2">
                    <Pill active={sailSetIndex === 0} callback={() => setSailSetIndex(0)} content='North Sails (Vannes)' />
                    <Pill active={sailSetIndex === 1} callback={() => setSailSetIndex(1)} content='Technique Voile (La Trinité-Sur-Mer)' />
                </div>
                <ul className='grow flex gap-8 flex-col'>
                    {sailSet.map((sail, index) => <Sail key={index} sail={sail} /> )}
                </ul>
            </div>
        </div>
    )
}

Sails.displayName = 'Gréement';

export default Sails