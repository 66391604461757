import {useState} from 'react'
import ChevronDownIcon from './icons/ChevronDown'

const Sail = ({sail}) => {
    const [open, setOpen] = useState(false) 
    const {size, description, name} = sail
    return (
        <li>
            <div className='flex items-center select-none'>
                <b>{size}m² -</b> 
                <span className='ml-4'  dangerouslySetInnerHTML={{__html: name}}></span> 
                <span className={`transition-transform transition-duration-1000 ml-4 ${open ? 'rotate-180' : 'rotate-0'}`} onClick={() => setOpen(!open)}><ChevronDownIcon /></span>  
            </div>  
            <div
             className={`${open ? 'max-h-[500px] py-1' : 'max-h-0' } overflow-hidden transition-all transition-duration-1000`}
             dangerouslySetInnerHTML={{ __html: description}}
             >
            </div>
        </li>
    )
}

export default Sail