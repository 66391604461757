import React from 'react'

const PlanDePont = () => {
    return (
        <div id="Preparation" className='h-auto slide section'>
            <div className="slide-wrapper">
                <div className="flex items-end justify-between pr-8">
                    <h1 className='text-6xl font-black text-primary boat-title pl-2 -mb-2'>Plan de pont</h1>
                </div>
                <hr className="boatline h-2"/>
                <div className='grow  mt-4'>
                    <p className='mt-3'>Il a été décidé de modifier ce plan de pont en aout 2018 (nb: Une évolution de ce plan de pont est depuis standard sur les nouveaux ORC50). </p>
                    <p className='mt-3'>Les winchs primaires et les winchs pour le 3D ont été changés et descendus au niveau du cockpit, les sièges de barres ont été refaits sur base de moules ORMA60, recentrés pour éviter l’exposition du barreur dans une mer formée sans que cela ne nuise à son champ de vision. Un chantier, nécessitant l’ajout de renforts et de deux pods en carbone. Architectes ayants collaboré aux dessins, suivis et exécution de ce nouveau plan de pont: Benoit Cabaret et Morgane Schlumberger (Cabinet Verdier), réalisation: Charlie Capelle (Technologie Marine) </p>
                    <p className='mt-3'>L’ergonomie du travail au pied de mât (accès aux bosses de ris) a été revue en installant un box composite sur le guindeau à une hauteur de + 25cm. Cela évite les prise de manoeuvres, coincées dans le guideau et accessoirement cela facilite aussi la montée et descente du roof. </p>
                    <p className='mt-3'>Les 4 tack-lines sur constrictors des code 0, Spi de tête, spi de brise, J2 et J3 sont actionnée du pied de mât. </p>
                    <p className='mt-3'>Un cunningham en pied de mat sur bloqueur Lewmar (qui sert également pour amures ris 1,2, 3) est stratifié au mât.</p>
                    <p className='mt-3'>Modification de l’Arthur de mât: démultiplication supplémentaire.</p>
                    <p className='mt-3'> 2 Winchs primaires (voiles près et reaching) Harken 3 vitesses, 650 carbone racing (idem IMOCA)</p>
                    <p className='mt-3'>2 Winchs secondaires (dérives et 3D) Harken 2 vitesses, 60 racing </p>
                    <p className='mt-3'>La GV 3di est équipée de 2 hook de ris karver, ris 1 et 2, dont les commandes reviennent au pied de mât, évitant de devoir cravater les ris aux points d’écoutes. Et surtout cela évite l’usure des bosses.</p>
                    <p className='mt-3'>2 bibs : placés sur la poutre arrière, et trappe dans le trampoline fermée par bâche: plus d’accidents de pieds coincés entre le bib et le trampoline.</p>
                    <p className='mt-3'>Un filet, double triangle large, a été placé en avant de la poutre, facilite les interventions en bout de bout-dehors.</p>
                    <p className='mt-3'>Un passage (Antal) est prévu à l’avant du roof pour l’envoi de la GV avec la poupée du guindeau électrique. Evite l’usage d’un winch électrique en pied de mât.</p>
                    <p className='mt-3'>Séparation et renforcement de la barre de liaison carbone entre les deux safrans (évite le fléchissement de cette barre de liaison dans la brise), renvoi sur chariot et rail Harken centre sur poutre arrière. Permet un meilleur réglage indépendant de chaque safran.</p>
                    <p className='mt-3'>Rotules inox en remplacement des 2 diabolos d’origine, sur la connexion barres franches-barre de liaison.</p>
                    <p className='mt-3'>Articulation des 2 barres, après le carré de barre afin de permettre le relevage des barres, sans forcer sur la barre de liaison.</p>

                </div>
            </div>
        </div>
    )
}
PlanDePont.displayName = 'Plan de pont'
export default PlanDePont