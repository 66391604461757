import React from 'react'

const Interieur = () => {
    return (
        <div id="Preparation" className='h-auto slide section'>
            <div className="slide-wrapper">
                <div className="flex items-end justify-between pr-8">
                    <h1 className='text-6xl font-black text-primary boat-title pl-2 -mb-2'>Intérieur</h1>
                </div>
                <hr className="boatline h-2"/>
                <ul className='grow'>
                    <li className="mt-3">Coque BB: restée totalement standard: WC et salle d’eau séparée, deux grandes cabines doubles lits 160 et 140 et lit 110cm dans coursive. Dessal 60Lh dans coffre cabine avant. Système de distribution eau dessal sur réservoirs BB et TB. Ballon eau chaude, 40l BB (idem TB) . </li>
                    <li className='mt-3'>Coque TB : Aménagement en coque propriétaire, chauffage par air pulsé (wallas, 3 sorties, thermostat et commande déportée) . Cabine arrière standard lit 160 avec supplément matelas latex, coursive rangements sur bordés BB et TB, Penderie humide avec assèchement pour cirés. Grande salle d’eau, grande douche. Ajout ouvrants dans le roof des cabines arrières BB et TB pour ventilation 3 points des cabines. Stores. Ventilateur cabine arr. TB. </li>
                    <li className='mt-3'>Carré modifié: grande table à carte, avec ventilation extracteur de chaleur des équipements électroniques. cuisine avec retour d’étagères pour bon calage en navigation : étagères structurelles améliorant la rigidité de la plate forme. Frigo/surgélateur à tiroirs sous plan de travail et congélateur séparé dans meuble structurel à côté de la TAC. </li>
                    <li className='mt-3'>Modification des cailles avant BB et TB: structure de fixation HB pour longues traversées, étagères, pad-eye pour bouteille de plongée et équipement kite / …</li>
                    <li className='mt-3'>2 poubelles: 1de mer et 1 poubelle standard à sac </li>
                    <li className='mt-3'>Changements en 2021 de tous les planchers de coques, fabrication custom en sandwich carbone, gains de poids (30kg) et facilité de manipulation</li>
                    <li className='mt-3'>Tiroirs supplémentaires</li>
                    <li className='mt-3'>Equipets supplémentaires meubles carré BB </li>
                    <li className='mt-3'>Portes étanches descentes coque à BB et TB, amovibles (passés contrôle sécu Rhum 2018) </li>
                    <li className='mt-3'>Modularité des tables carré et cockpit (3 unités amovibles) </li>
                    <li className='mt-3'>Nouvelles sortie chauffage:  cuisine, salle d’eau, cab TB et penderie à cirés</li>
                    <li className='mt-3'>Robinet eau de mer cuisine </li>
                    <li className='mt-3'>Robinet eau de mer haute pression dans le cockpit </li>
                    <li className='mt-3'>Robinet eau douce / douche de pont cockpit </li>
                </ul>
            </div>
        </div>
    )
}

Interieur.displayName = 'Intérieur'
export default Interieur