import { useEffect, useState } from 'react';
import * as SlidesCmponents from './slides'
import ReactTooltip from 'react-tooltip';



const Slides = () => {
    const slidesCmponents = [SlidesCmponents.Landing, SlidesCmponents.Presentation, SlidesCmponents.Preparation, SlidesCmponents.Sails, SlidesCmponents.PlanDePont, SlidesCmponents.PlanAntiDerive, SlidesCmponents.Interieur, SlidesCmponents.Electronique]
    const [slides, setSlides] = useState([])
    const [visibleSlide, setVisibleSlide] = useState(0)
    
    const scrollToSlideIndex = (index) => {
        scrollToSlide(slides[index])
    }
    const scrollToSlide = (slide, i) => {
        window.scrollTo(0, slide.offsetTop + 1)
    }

    useEffect(() => {
        const htmlSlides = [...document.querySelectorAll('.slide')]
        setSlides(htmlSlides)

        const handleScroll = (event) => {
            const active = htmlSlides.findIndex((slide) => (Math.round(window.scrollY) >= slide.offsetTop) && (Math.round(window.scrollY) < slide.offsetTop + slide.offsetHeight))
            setVisibleSlide(active)
        };
        
        window.addEventListener('scroll', handleScroll);
        handleScroll()
    }, []);

    useEffect(() => {
        ReactTooltip.rebuild();
    })

    return (
        <div>
            <ReactTooltip id='nav' effect="solid" />
            <div className='fixed top-6 flex p-1 justify-center gap-6 z-50 w-full lg:w-1/2 '>
                {slides.map((slide, i) => <div data-for='nav' data-tip={slidesCmponents[i].displayName} key={i} onClick={() => scrollToSlide(slide)} className={`${visibleSlide === i ? 'opacity-100 ' : 'opacity-50'} bg-primary border-2 rounded-full h-4 w-4  transition-all transition-1000`}></div>) }
            </div>
            <div>
                {slidesCmponents.map((Comp, i) => <Comp scrollToSlideIndex={scrollToSlideIndex} key={i} />)}
            </div>
        </div>
        
    )
}

export default Slides