
const Presentation = () => {
    return (
        <div id="Presentation" className='h-screen slide section'>
            <div className="slide-wrapper">
                <div className="flex items-end justify-between pr-8">
                    <h1 className='text-6xl font-black text-primary boat-title pl-2 -mb-2'>Présentation</h1>
                </div>
                <hr className="boatline h-2"/>
                <h2 className="text-2xl font-black text-primary boat-title text-left pl-2">Un bateau a double objectif</h2>
                <div className='text-lg'>
                    <p className='my-4'>Le cahier de charge initial, partait du précédent TS42 (Zed6),  catamaran de croisière rapide, orienté course. 2 transats, 1 Fastnet,1  Armen Race , … 3 podiums plus tard,  Zed7 devait garder le même ADN,  mais en insistant sur deux contraintes :</p>
                    <ul>
                        <li>un programme solo en vue de la route du rhum.</li>
                        <li>une reconversion 2019-2020 un vue d’une grande croisière en couple.</li>
                    </ul>
                    <p className='my-4'>L’ergonomie du plan de pont, la limitation des déplacements et la simplification des manœuvres étaient des objectifs essentiels.</p>
                </div>
            </div>
        </div>
    )
}
Presentation.displayName = 'Présentation'

export default Presentation