import "@fontsource/bai-jamjuree";
import {useState} from 'react'

import ExpandIcon from './components/icons/Expand'
import DexpandIcon from './components/icons/Dexpand'
import Gallery from './components/Gallery'
import Slides from './Slides'

function App() {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="App">
      <div className='flex'>
        <div className='lg:w-1/2 relative'>
          <Slides />
        </div>
        <div className={`h-screen  top-0 ${expanded ? 'fixed w-screen block' : 'lg:w-1/2 sticky hidden' } lg:block z-[200]`}>
          <div className='absolute top-8 right-8' onClick={() => setExpanded(!expanded)}>
            {expanded ? < DexpandIcon /> : <ExpandIcon /> }
          </div>
          <Gallery setExpanded={setExpanded} expanded={expanded} />
        </div>
        <div onClick={() => setExpanded(true)} className='fixed bottom-16 pill text-3xl !px-8 left-1/2 -translate-x-1/2 rounded-full active lg:hidden'>Photos</div>
      </div>
    </div>
  );
}

export default App;


