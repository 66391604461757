import ChevronDownIcon from "../components/icons/ChevronDown"
import {useEffect, useRef, useCallback} from 'react'

const Landing = ({scrollToSlideIndex}) => {
    const arrowRef = useRef()

    const scrollReminder = useCallback(() => {
        if (window.scrollY === 0) {
            arrowRef.current.classList.remove('opacity-0', 'pointer-events-none')
            arrowRef.current.classList.add('opacity-50')
            setTimeout(() => {
                arrowRef.current.classList.remove('opacity-50')
                arrowRef.current.classList.add('opacity-0', 'pointer-events-none')
            }, 4000)
            setTimeout(scrollReminder, 15000)
        }
    }, [])


    useEffect( () => {
        setTimeout(scrollReminder, 15000)
    },[scrollReminder])

    return (
        <div id="home" className="h-screen slide section relative">
            <div className="slide-wrapper">
                <div className="flex items-end justify-between pr-8">
                    <h1 className='text-8xl font-black text-primary boat-title pl-2 -mb-3'>SQUID</h1>
                    <h2 className="text-2xl font-black text-primary boat-title pl-2">ZED 7 - TS5 / ORC50</h2>
                </div>
                <hr className="boatline h-4"/>
                
                <p className='my-8 xl:w-2/3 w-3/4 '>
                Catamaran dessiné par Christophe Barreau, construit au chantier Marsaudon Composite, mis à l'eau en mars 2018.
                </p>
                <p className='my-2'>
                    <b className='pr-2'>
                     A vendre:
                    </b>
                    980.000€ HTVA
                </p>
                <p>Contact: <a className='underline' href="mailto:gbibot@yahoo.com">gbibot@yahoo.com</a></p>
                <p>Visible Îles Canaries</p>
                {<div ref={arrowRef} onClick={() => scrollToSlideIndex(1)} className="opacity-0 transition-all absolute bottom-6 left-1/2 !-translate-x-1/2 "> 
                    <div className='animate-bounce'>
                        <ChevronDownIcon />
                    </div> 
                </div>}
                
            </div>
        </div>
    )
}

Landing.displayName = 'Home'

export default Landing
