
const Preparation = () => {
    return (
        <div id="Preparation" className='slide section'>
            <div className="slide-wrapper">
                <div className="flex items-end justify-between pr-8">
                    <h1 className='text-6xl font-black text-primary boat-title pl-2 -mb-2'>Préparation et chantiers</h1>
                </div>
                <hr className="boatline h-2"/>
                <div className='grow'>
                    <p className='mt-4'> 
                        En phase préliminaire, sur base d’échanges avec l’architecte, le bureau d’étude du chantier et quelques coureurs de La Trinité sur Mer nous avons définit les grandes lignes de la structure et des appendices, afin d’arrêter un budget, estimatif de l’ordre de 1,3 M EUR avant reconversion croisière. Il fallait donc  se concentrer sur un gréement et des appendices performants, augmenter la rigidité de la plate-forme et maintenir un devis poids minimum:
                    </p>  
                    <ul>
                        <li>Coques et nacelle: en vynilester,</li>
                        <li> Cloisons structurelles avant et arrière, poutres avant et arrière, roof : en carbone</li>
                        <li>Mât carbone rotatif, haut module</li>
                        <li> Gréement dormant textile (avril 2022)</li>
                        <li> Martingale textile (nov. 2022)</li>
                    </ul> 
                    
                    <p className='mt-4'>
                        L’étude du mât a été poussée permettant l’envoi d’un jeu de voile étendu,  croisière ou régate et limiter la compression et l’usure des drisses des voiles de portant
                    </p>    
                    <ul>
                        <li>hook de Code0/gennaker (ramené au capelage en 2021) et spi de brise</li>
                        <li>hook J2</li>
                        <li>Drisse simples pour soi de tete et drisse d’homme</li>
                        <li> Drisse moufflée  J3 et TMT</li>
                    </ul> 
                </div>
            </div>
        </div>
    )
}
Preparation.displayName = 'Préparation'
export default Preparation