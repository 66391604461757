import React from 'react'

const PlanAntiDerive = () => {
    return (
        <div id="Presentation" className='slide section'>
        <div className="slide-wrapper">
            <div className="flex items-end justify-between pr-8">
                <h1 className='text-6xl font-black text-primary boat-title pl-2 -mb-2'>Plan Anti Derive</h1>
            </div>
            <hr className="boatline h-2"/>
            <div className='grow text-lg'>
                <p className='mt-3'>Les dérives de ZED7 sont les anciennes dérives de Gitana 80, asymétriques, dessinées par Bruce Farr, carbone, raccourcies de 40 cm de telle sorte que le T-E mini et maxi (140 – 295) ne change quasi pas par rapport au bateau standard et permettent de se glisser à la côté dans les zones de mouillages fréquentés. </p>
                <p className="mt-3">Les puits de dérives ont été renforcés structurellement avec calculs du b-e du chantier. L’échantillonnage et la rigidité des dérives par rapport aux modèles standards, est améliorée, pour un poids équivalent. </p>
                <p className="mt-3">Le gain par rapport aux dérives standards carbone est de 5 à 8 degrés au près, selon l’état de la mer (constaté lors de du GP des multicoques et Route du Rhum 2018). La différence est frappante dans du vent light et medium (range 42-55 TWA , 5-18 kts TWS) </p>
            </div>
        </div>
    </div>
    )
}

PlanAntiDerive.displayName = 'Plan anti derive'

export default PlanAntiDerive