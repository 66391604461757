import {useState, useEffect, useRef} from 'react'
import ReactTooltip from 'react-tooltip'
import ChevronDownIcon from './icons/ChevronDown';

function importAll(r) {
    let images = {};
     r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}

const images = Object.values(importAll(require.context('../imgs', false, /\.(png|jpe?g|svg)$/)));

const Gallery = ({expanded, setExpanded}) => {
    const [imgIndex, setImgIndex] = useState(0)
    const refs = useRef([])

    const handleImgClick = (event) => {
        if (event.detail === 2) {
            setExpanded(!expanded)
        }
    }

    useEffect(() => {
        refs.current[imgIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
        })
    }, [imgIndex])

    return (
        <div className='flex flex-col justify-center h-full w-full p-12 transition-all bg-tercary select-none'>
            <div className='grow justify-between flex items-center min-h-0'>
                <span onClick={() => imgIndex !== 0 && setImgIndex(imgIndex - 1)} className='rotate-90'>
                    <ChevronDownIcon />
                </span>           
                <img onClick={handleImgClick} src={images[imgIndex]} alt="" className='max-w-[95%] min-h-0 max-h-full' />
                <span onClick={() => imgIndex !== (images.length - 1) && setImgIndex(imgIndex + 1)} className='-rotate-90'>
                    <ChevronDownIcon />
                </span>
            </div>
            <div className='overflow-x-auto shrink-0 h-20 flex my-4 snap-x'>
                {images.map((img, i) => <img 
                        data-tip={i}
                        data-for='pic'
                        alt=""
                        key={i} 
                        src={img}
                        ref={(element) => {refs.current[i] = element}} 
                        onClick={(e) => {
                            setImgIndex(i)
                        }} 
                        className={` h-full mr-2 ${i === imgIndex ? 'border-primary' :'border-tercary'} border-4`}
                    />
                )}
            </div>
            <ReactTooltip id='pic' effect="solid" />
        </div>
    )
}

export default Gallery