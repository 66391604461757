import React from 'react'

const Electronique = () => {
    return (
        <div id="Preparation" className='slide section'>
            <div className="slide-wrapper">
                <div className="flex items-end justify-between pr-8">
                    <h1 className='text-6xl font-black text-primary boat-title pl-2 -mb-2'>Électronique</h1>
                </div>
                <hr className="boatline h-2"/>
                <div className='grow mt-4'>
                    <p className='mt-3'>Afin de se prémunir d’un abandon pour cause d’électronique défaillante, Zed7 à suivi un cahier de charge off-shore solo, conçu par la société Skysat avec qui nous collaborons depuis 2007 pour la réalisation de deux classes40 et de deux catamarans TS. L’inventaire est très complet et redondant: </p>
                    <ul>
                        <li>1 Centrale B&G, H 5000  et 1 Centrale Inertielle GPSLT1000</li>
                        <li>2 Pilotes et  2 vérins,1 BB et 1TB: 2 </li>
                        <li>2 x aériens </li>
                        <li>1 radar 4G </li>
                        <li>2 télécommandes pilote B&G / BT</li>
                        <li>1 Zeus 19  MFD à la TAC</li>
                        <li>4 écrans H5000 Graphic Display</li>
                        <li>Second capteur d’angle de mât, dans table à carte, pour réglage manuel en cas de problème d capteur principal. </li>
                        <li>AIS, VHF </li>
                        <li>Data iridium 9555 </li>
                        <li>PC + Adrena et dongle</li>
                        <li>ACS (anti capsize system) avec télécommande sans fil </li>
                        <li>Convertisseur 12-220 V, 800 W avec deux prises 220 à la table à carte </li>
                        <li>Chargeur quai intégré avec circuit 220 V dans carré </li>
                        <li>Réveil « race » à la table à carte </li>
                        <li>Tableau électrique pilote séparé avec switchs BB/TB , afin de se prémunir d’une perte pilote due à une perte de d’alimentation du tableau principal.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
Electronique.displayName = 'Électronique'
export default Electronique